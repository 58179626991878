html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
tr {
  height: 10px;
}

tr td {
   height: auto !important;
}
fieldset {
  border: 0;
  margin-inline: 0;
  padding-block:0;
  padding-inline:0;
}